body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 800px;
  margin: 0 auto;
  padding: 8px;
}

h2 {
  padding: 0 8px;
  font-weight: normal;
  margin: 16px 0 8px 0;
  text-transform: uppercase;
  text-decoration: underline;
}

.Item {
  margin-bottom: 4px;
  line-height: 1rem;
  padding: 4px 8px;
  border-radius: 4px;
  color: #444;
}

.Item:nth-of-type(odd) {
  background: #f2f2f2;
}

.Item__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.Item__name {
  text-transform: uppercase;
  margin-right: 6px;
  font-weight: bold;
}

.Item__favorite {
  cursor: pointer;
}

.Item__favorite::before {
  content: "\2606";
}
.Item__favorite.favorite::before,
.Item__favorite:hover::before {
  content: "\2605";
  color: rgb(197, 23, 23);
}

.Item__price {
}

.Item__ingredients {
  flex: 1;
  margin-top: -4px;
}

.Item__ingredient {
  border-radius: 8px;
  display: inline-block;
  margin: 4px 4px 0 0;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.Item .Item__ingredient:not(:last-child)::after {
  content: ", ";
}

.Item__ingredient:hover,
.Item__ingredient:focus {
  /* box-shadow: 0 0 0 1px black; */
  text-decoration: underline;
}

.header {
  text-align: center;
  line-height: 1.5rem;
}

.filters {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  margin: 16px 0;
}

.filters h3 {
  text-transform: uppercase;
  font-weight: normal;
  padding: 0;
  margin: 0 0 16px 0;
}
